@import './sass/variables';
@import '@fgb/portal-component-library/styles';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'ngx-toastr/toastr';

/* ELEMENT STYLING */

html {
  background-image: none;
  min-height: 100vh;
  width: 100%;

  body {
    width: 100%;
    font-family: $font-default;
    background: $black;
    color: $white;
  }
}

h1,
h2,
h3,
h4,
h5,
.title-font {
  font-family: 'eurostile', sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: capitalize;
}

/* BOOTSTRAP EXTENSIONS */

.cursor-pointer {
  cursor: pointer !important;
}

.btn-circle {
  border-radius: 50%;
}

$primary: $primary-color;

/* BOOTSTRAP OVERRIDES */
$app-colors: (
  'primary': $primary-color,
  'secondary': $secondary-color,
  'tertiary': $tertiary-color,
  'quaternary': $quaternary-color,
  'primary-alternate': $primary-alternate,
  'muted': $muted-color,
  'disabled': $disabled-color,
  'info': $info-color,
  'grey': $grey-color,
  'success': $success-color,
  'danger': $error-color,
  'marketplace': $marketplace-color,
  'mp-unique-code': $tertiary-color,
  'mp-download': $secondary-color,
  'mp-event': $events-color,
  'lotto': $lotto-color,
  'wallet': $wallet-color,
  'points': $points-color,
  'white': #ffffff,
  'black': #000000,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
);

$sizes: (
  90: 90%,
);

$grid-gutter-width: 22px;

$enable-rounded: true;
$enable-validation-icons: false;

$border-radius-base: 4px;

$box-shadow-sm: 0 0 0.25rem rgba(#000, 0.15);
$box-shadow: 0 0.1rem 0.5rem rgba(#000, 0.15);

$date-select-box-shadow: 0 0 0 0.2rem rgb(60 85 124 / 25%);

/* Form Colors */

.form-control {
  color: #000000;
}

/* Banner */

.banner {
  background-size: cover !important;
  justify-content: center;
  background-image: url(~src/assets/images/mobile-banner.jpg);
  @media (min-width: 991px) {
    padding-left: 3rem;
    background-image: url(~src/assets/images/banner.jpg);
  }

  h1 {
    color: $white;
    font-size: 40px;
    text-transform: uppercase !important;
    @media (max-width: 991px) {
      font-size: 20px;
    }
  }
}

.login-banner {
  background-image: url(~src/assets/images/login-registration/login-banner-v2.jpg);
  background-size: cover;
  background-position: center;
}

.rounded {
  border-radius: 16px;
}

/* Placeholders */

.no-items {
  .no-results-placeholder-description {
    font-size: 0.875rem;
    background-color: $tertiary-color;
    color: $white;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.no-items.no-results-placeholder-div {
  background-color: $tertiary-color;
  color: $white;
  border: none;
}

.redeem-list-item-icons {
  font-size: 18px;
}

/* Errors */

.alert-danger {
  padding: 0 !important;
  margin-top: 4px;
  font-size: 0.625rem;
  background-color: white !important;
  color: $error-color !important;
  border: none !important;
}

.nav.nav-secondary {
  background-color: $tertiary-color;
  .nav-item {
    &.active,
    &:active,
    &:hover {
      background-color: transparent;
    }
    background-color: transparent;
  }
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;
  }
}

.nav.nav-secondary .nav-item {
  .icon,
  label {
    color: $white;
  }
}

.announcements-carousel {
  .owl-item {
    @media only screen and (min-width: 992px) {
      margin-left: 6px !important;
      margin-right: 2px !important;
      width: 944px !important;
    }
  }
  .owl-carousel .owl-stage-outer {
    padding-left: 0px !important;
  }
}

fgbcl-number-input {
  .quantity-select-container {
    margin: auto !important;
  }
}

.invalid-token-modal {
  max-width: 700px;

  .modal-content {
    min-height: 280px;
    padding: 15px;
  }
}

/** Games Page */
.games {
  .d-lg-none {
    .owl-carousel {
      .owl-stage-outer {
        @media (max-width: 991px) {
          padding-left: 0px;
        }
        .owl-item {
          @media (max-width: 991px) {
            padding: 0px 5px;
          }

          .survey-container {
            &.bg-poll {
              @media (max-width: 991px) {
                margin-right: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}

/** Scorecard Carousel */
.badge-carousel-div {
  .d-lg-none {
    .owl-carousel {
      .owl-stage-outer {
        @media (max-width: 991px) {
          padding-left: 0px;
        }
        .owl-item {
          @media (max-width: 991px) {
            padding: 0px 5px;
          }
        }
      }
    }
  }
}

@import 'bootstrap/scss/bootstrap';

/* Requiring Bootstrap */
@each $color, $value in $theme-colors {
  .text-on-#{$color} {
    color: color-contrast($value, $dark, $light) !important;
  }

  .svg-#{$color} {
    color: $value;
    .cls-1 {
      fill: $value !important;
      stroke: $value !important;
    }
    .circle {
      fill: $value !important;
      stroke: $value !important;
    }
  }
}

.modal-content {
  border-radius: 16px;
}

.card-discount-level {
  margin-right: 30px;
}

/* Earn Points */

.earn-points-container .earn-card-body .earn-card-points-value {
  padding-bottom: 10px;
}

.earn-card-body {
  .col-9 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

/* FAQs */

.faq-section-card-title,
.faq-section-card-description {
  font-size: 0.875rem;
}

/* Registration */

fgbcl-registration-terms-and-conditions {
  .registration-terms-and-condition h5 {
    color: $black;
    margin-bottom: 10px;
    text-align: left !important;
  }

  .terms-section-box {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  @media (max-width: 991px) {
    .registration-terms-and-condition .content {
      max-height: calc(100vh - 230px);
    }
    .registration-terms-and-condition,
    .wrap-accept-box,
    .registration-navigataion-button-container {
      padding: 11px;
    }
  }
}

/* Rewards */

fgbcl-marketplace-details {
  .rewards-item-panel-status {
    @media (max-width: 991px) {
      margin-top: 1rem !important;
    }
  }
}

.rewards-details-desc-terms {
  margin-top: 30px;
}

.marketplace-wallet-download-btn {
  span {
    color: $black;
  }
}

fgbcl-marketplace-attributes {
  .modal-backdrop.fade {
    opacity: 0.15 !important;
  }
}

.rewards-button-background {
  background-color: $tertiary-color;
}

.bid-amount-input.form-control,
.rewards-terms-unchecked {
  color: $white;
}

.purchase-confirmation-title,
.purchase-confirmation-name,
.confirmation-attribute-value,
.confirmation-custom-field-value,
.quantity-value,
.point-value,
.btn-purchase-cancel,
.lotto-modal-entries,
.lotto-modal-points-value {
  color: $white !important;
}

.rewards-item-description,
.rewards-item-description-title,
.rewards-item-terms-text {
  color: $white !important;
}

.rewards-details-desc-terms .accordion-button:not(.collapsed) {
  color: $primary-color !important;
}

fgbcl-featured-rewards {
  .material-icons.ps-2 {
    color: $white !important;
  }
}

fgbcl-marketplace-details,
fgbcl-lotto-details,
fgbcl-auction-details {
  .accordion-button::after {
    --bs-accordion-btn-active-icon: url(~src/assets/images/placeholders/dropdownarrow.svg);
    background-image: url(~src/assets/images/placeholders/dropdownarrow.svg);
  }
}

.btn-select-attribute {
  background-color: $tertiary-color;
  color: $white;
}

.marketplace-attribute .form-attribute .form-select {
  background-color: $tertiary-color;
  border: 1px $dark-grey-color;
  color: $white;
}

.offcanvas-attribute {
  background-color: $tertiary-color;
  color: $white;
}

fgbcl-marketplace-attributes {
  .form-select {
    background-image: url(~src/assets/images/placeholders/dropdownarrow.svg);
  }

  .marketplace-attribute .form-attribute .form-attribute-label {
    color: $white !important;
  }
}

.offcanvas-attributes-title {
  color: $white;
}

.offcanvas-attribute .offcanvas-attribute-btn {
  background-color: transparent !important;
  color: $white !important;
}

.offcanvas-header .btn-close {
  color: $white !important;
}

.mobile-select-attribute .selected-label {
  color: $white !important;
}

#marketplaceDescTerms {
  --bs-accordion-bg: transparent;
}

fgbcl-marketplace-custom-fields {
  .form-floating {
    color: $white;
  }
  .custom-field-form,
  .custom-field-form:focus {
    color: $white !important;
    background-color: $tertiary-color;
    border: 1px solid $dark-grey-color;
  }
  .error-messages {
    background-color: transparent !important;
  }

  .display-name-custom-field {
    color: $white !important;
  }
}

.form-floating > label::after {
  background-color: transparent !important;
}

fgbcl-marketplace-purchase-confirmation,
fgbcl-confirm-modal {
  hr {
    opacity: 1;
  }

  .btn-cancel {
    color: $white !important;
  }
}

fgbcl-confirm-modal {
  .modal-footer {
    border-top: 1px solid $white !important;
  }
}

.status-text-on-not-enough-points,
.text-rewards-danger,
.status-text-on-limit-reached {
  color: $secondary-color;
}

.status-text-on-sold-out,
.status-text-on-coming-soon {
  color: $white;
}

.status-container {
  margin-bottom: 25px;
}

.rewards-details-desc-terms {
  margin-top: 25px;
}

/* Wallet */

fgbcl-rewards-wallet-redeem-list-item {
  .redeem-card {
    background-color: $tertiary-color !important;
    color: $white !important;
  }
}

.rewards-wallet-redeemed-list .col-12 .redeemed-border {
  border: 1px solid #272727 !important;
}

.rewards-wallet-redeem-title,
.wallet-back-button {
  color: $white;
}

.wallet-container {
  background-color: $tertiary-color !important;
}

.wallet-info-box {
  background-color: $tertiary-color;
  border: 1px solid $tertiary-color !important;
}

fgbcl-marketplace-wallet-details {
  .qr {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .loyalty-details .text-danger {
    color: $white !important;
  }
}

fgbcl-marketplace-purchased-list-item {
  .qr {
    margin-top: 0.25rem !important;
  }
}

fgbcl-marketplace-wallet-details,
fgbcl-lottos-wallet-details {
  hr {
    border-top: 1px solid #272727;
  }
}

.marketplace-wallet-claimed,
.lotto-wallet-claimed,
.marketplace-wallet-expiry {
  color: $white;
}

.countdown-timer {
  color: $secondary-color;
}

.reward-status {
  background-color: $tertiary-color !important;
  label {
    color: $white !important;
  }
}

fgbcl-mixed-loyalty-list {
  @media (max-width: 991px) {
    .no-items.no-results-placeholder-div {
      margin: 0;
    }
  }
}

/* Shared */

.modal-content {
  background-color: $tertiary-color;
}

.modal-body {
  padding: 1rem 0 !important;
}

.nav.nav-secondary .nav-item.active {
  @media (max-width: 991px) {
    width: 5%;
  }
}

.short-long > .btn-toggle {
  color: $primary !important;
  border: none !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

fgbcl-date-range-picker {
  .from-date-text,
  .to-date-text,
  .transaction-arrow {
    color: $white !important;
  }

  .date-field,
  .date-range-picker-icon {
    background-color: $black;
    border: 1px solid #272727 !important;
    color: $white !important;
  }

  .date-range-picker .btn.date-range-picker-icon {
    border-left-style: solid !important;
    border-left-color: rgb(39, 39, 39) !important;
    border-left-width: 1px !important;
  }

  .form-control:focus {
    background-color: $black;
    box-shadow: none;
  }
}

/* Scorecards */

.action-img-container.bg-disabled,
.scorecard-modal-action-img.bg-disabled {
  background-color: #707070 !important;
  border-color: #707070 !important;
}

.scorecard-one-punch-incomplete-name {
  background-color: $info-color;
}

.progress,
.circle-empty {
  background-color: $info-color;
}

.dashed-line,
.dashed-line-model-v2 {
  border-right: 2px dashed $info-color !important;
}

.bg-carousel {
  min-height: 300px;
}

.scorecard {
  background-color: $tertiary-color;
  border: none;
}

.scorecard-label-ellipsis,
.scorecard-punchcard-steps,
.scorecard-milestone-value,
.scorecard-milestones-prize-text,
.scorecard-punchcard-prize-text,
.milestone-info-btn,
.punchcard-info-btn {
  color: $white;
}

.scorecard-milestones-prize-label,
.scorecard-punchcard-prize-label {
  color: $white !important;
}

.scorecard-prize-border {
  background-color: $primary-color;
  color: $white;
}

.badge-container {
  background-color: $tertiary-color;
  border: none !important;
}

.scorecard-locked-name {
  color: $white;
}

.scorecard-locked-header {
  border: 1px solid #707070;
}

.scorecard-locked-footer,
.scorecard-description-modal-challenge-locked-box {
  background-color: #707070;
  color: $white;
  border: none;
}

.scorecard-description-modal-next-challenge-box {
  border: 1px solid #707070;
}

.scorecard-description-modal {
  background-color: $tertiary-color;
  border-bottom: none;
  .scorecard-description-modal-name,
  .scorecard-Milestone-Milestones-Value-model-v2,
  .scorecard-description-modal-prize-v2 {
    color: $white;
  }
  .scorecard-description-modal-prizebox {
    background-color: $primary-color;
    color: $white;
  }
  .scorecard-description-modal-desc-terms .accordion-button {
    background-color: $tertiary-color;
    color: $white !important;
  }
  .accordion-button {
    --bs-accordion-btn-active-icon: url(~src/assets/images/placeholders/dropdownarrow.svg);
  }
  .accordion-button.collapsed::after {
    background-image: url(~src/assets/images/placeholders/dropdownarrow.svg);
  }
  .accordion-button:not(.collapsed) {
    color: $primary-color !important;
  }
  #scorecard-desc,
  #scorecard-terms {
    background-color: $tertiary-color;
    color: $white;
  }
  .short-long-description-text {
    color: $white;
  }
  .scorecard-description-modal-more-btn {
    color: $white;
  }
  .modal-close-button {
    .text-grey {
      color: $white !important;
    }
  }
}

.scorecard-description-modal-carousel {
  background-color: $primary-color;
  .next,
  .previous,
  .current-step,
  .total-step {
    color: $white !important;
  }
  .steps .dash {
    background: $white !important;
  }
}

.scorecard-description-modal .scorecard-description-modal-dash-line-top {
  font-size: 40px;
  border-top: 1px solid #707070;
  margin: 16px 16px 16px;
}

/* Announcements */

.announcements-container {
  background-color: $tertiary-color;
  color: $white;
  border: none !important;
}

.announcement-link {
  color: $white;
}

/* Tertiary */

.terms-section-box {
  background-color: $black;
}

fgbcl-contact-us {
  .contact-us-box {
    background-color: $tertiary-color;
    border: none !important;
  }

  .contact-us-input-group {
    background-color: $black;
    color: $white;
    border: none !important;
    padding-left: 0.5rem !important;
  }

  .alert-danger {
    background-color: transparent !important;
  }
}

.earn-sub-heading {
  color: $white !important;
}

.earn-points-container .earn-card-body {
  background-color: $tertiary-color;
}

fgbcl-faq-section-card {
  .accordion-item {
    border: none;
    background-color: $tertiary-color;
  }
  .faq-section-card-title {
    background-color: $tertiary-color;
    color: $white;
    border: 1px solid #272727;
  }
  .faq-section-card-description {
    color: $white;
  }
  .accordion-button::after {
    background-image: url(~src/assets/images/placeholders/dropdownarrow.svg);
  }
}

fgbcl-errors {
  .alert-danger {
    background-color: transparent !important;
    color: white !important;
  }
}

fgbcl-number-input {
  .form-control:focus {
    background-color: transparent !important;
    box-shadow: none;
  }
}

.page-link:focus {
  box-shadow: none;
}

/* Registration */

fgbcl-registration-navigation {
  .btn:disabled {
    border: none;
  }
}

fgbcl-registration-terms-and-conditions {
  .wrap-accept-box .accept-box-label {
    font-size: 0.875rem;
    margin-top: 3px;
    margin-bottom: 0;
  }

  h5 {
    color: $primary-color !important;
  }
}

/* Games */

fgb-polls-page {
  .countdown-timer {
    color: $white;
  }
}

/* Colour Update */

.badge-carousel-subheading,
.scorecard-featured-link,
.scorecard-featured-link-icon,
.featured-rewards-heading,
.featured-rewards-text,
.announcements-main-title,
.rewards-item-panel-name,
.badge-subheading,
.badge-title,
.rewards-item-name,
.wallet-lotto-item-name,
.marketplace-item-name,
.faq-section-title,
.transaction-date,
.marketplace-wallet-item-name,
.lotto-wallet-item-name,
.marketplace-unique-code-wallet,
.wallet-item-purchased-value,
.wallet-item-purchased-subheading,
.marketplace-unclaimed,
.no-results-placeholder,
.text-success-page,
.rewards-item-terms {
  color: $white !important;
}
