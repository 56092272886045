/* COLOURS */
$primary-color: #418fde;
$secondary-color: #ffcd00;
$tertiary-color: #081827;
$quaternary-color: #245953;
$primary-alternate: #fff;

$success-color: #198754;
$error-color: #dc3545;
$warning-color: #ff854f;
$info-color: #707070;
$grey-color: #999999;
$dark-grey-color: #272727;
$muted-color: #f7f7f7;
$disabled-color: #dedddd;
$payment-color: #04955f;
$white: #fff;
$black: #000;

$auction-color: $secondary-color;
$marketplace-color: $primary-color;
$lotto-color: $tertiary-color;
$wallet-color: $primary-color;
$points-color: $primary-color;
$ecash-color: $primary-color;
$events-color: $quaternary-color;
$predictor-color: $primary-color;
$poll-color: $tertiary-color;
$quiz-color: $tertiary-color;

$marketplace-0-color: $primary-color; // FortressGB
$marketplace-1-color: $primary-color; // Unique Code
$marketplace-2-color: $primary-color; // Download
$marketplace-3-color: $events-color; // Event
$marketplace-4-color: $tertiary-color; // Product
$wallet-color: $secondary-color;
$wallet-redeemed-color: $muted-color;

$navbar-color: $primary-color;
$navbar-link-color: $white;
$navbar-link-active-color: $white;
$navbar-progress-color: $secondary-color;
$navbar-secondary-color: $info-color;

/* FONTS */

$font-default: 'Open Sans', Arial, sans-serif;
$font-heading: 'eurostile', Arial, sans-serif;

/* GENERAL */

$navbar-height: 54px;
$navbar-mobile-height: 50px;
$footer-height: 160px;
$footer-mobile-height: 150px;
$panel-height: 150px;
$cta-height: 50px;
$main-padding-bottom: 40px;
$loyalty-image-height: 390px;
$border-radius: 16px;
$card-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.15);
$nav-secondary-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
$read-more-overlay-enabled: false;

/* Reward status disabled overrides */
$reward-status-disabled-colors: (
  'coming-soon': (
    $grey-color,
    $white,
  ),
  'not-enough-points': (
    $grey-color,
    $white,
  ),
  'sold-out': (
    $grey-color,
    $white,
  ),
  'limit-reached': (
    $grey-color,
    $white,
  ),
  'disable-entry': (
    $grey-color,
    $white,
  ),
  'item-locked': (
    $grey-color,
    $white,
  ),
);

$registration-layout-login-banner-background-image: url(~src/assets/images/login-registration/login-banner-v2.jpg) !default;
$registration-layout-login-banner-mobile-background-image: url(~src/assets/images/login-registration/login-banner-v2.jpg) !default;
$confirm-modal-btn-cancel-font-color: $white !important;
